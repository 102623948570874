import * as React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import burtleFamilyPhoto from "../images/burtleFamilyPhoto.png"

const AboutPage = () => {
  return (
    <Layout>
        <SEO title="About" description="Find out more about me and why you should trust Burtle Web Services to manage your website."  />
        <main className="container">
          <title>About</title>
          <div className="about align-items-center">
            <img src={ burtleFamilyPhoto } alt="Burtle Family" className="aboutPageImage"></img>
            <div className="aboutPageText align-items-center px-4">
            <h2>Hi, I'm Zach!</h2>
            <p>
                I'm Zach Burtle and I love building websites. I'm a software developer with { (new Date()).getFullYear() - 2013 } years of professional experience but I've been building websites since I was in 7th grade. 
                I started my career creating financial software for large governments at a company in Springfield, IL. After doing that for five
                years I felt that I wasn't growing as a developer anymore so I took a job with a consulting firm in Springfield. That was the best move of my career.
                I've been there for just over two years and I've worked on a dozen different projects of all types and sizes. The diversity in work is what I've
                always been craving and that's part of the reason I started Burtle Web Services.
            </p>
            <p>
                Burtle Web Services' goal is to give every small business a website presence on the internet. I've seen more and more businesses 
                opt for the social media only route. Social media is obviously a crucial part of any business no matter the size but it must
                be used in conjunction with a website and not in place of. Social media accounts are great for advertising and communicating updates
                to your customers but they are terrible for organizing and detailing your products or services. Some businesses choose to build their
                own websites with an 'easy' DIY builder but they never are actually easy. You need to focus on running your business, 
                let me focus on building and maintaining your website.
            </p>
            </div>
          </div>
        </main>
    </Layout>
  )
}
  
export default AboutPage